let _ = require('lodash');
window.$ = window.jQuery = require('jquery');
$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});

require('slick-carousel')
require('sweetalert')
require('./contact')
require ('./sliders')

$('.toForm').click(function () {
    $('html, body').animate({
        scrollTop: $('#formContacto').offset().top - 100
    });
});

$('.clicCall').click(function () {
    gtag('event', 'clic_call', { 'event_category': 'call', 'event_label': 'landing', 'value': '1'});
    window.open('tel:+573205720368', '_self');
    fbq('track', 'Contact');
});
$('.openMainSite').click(function () {
    gtag('event', 'clic_top_logo', { 'event_category': 'home', 'event_label': 'landing', 'value': '1'});
    window.open('https://radiocomunicaciones.co/', '_blank');
});

$('.wp-float-desktop').click(function () {
    gtag('event', 'clic_float_wp', { 'event_category': 'whatsapp', 'event_label': 'landing', 'value': '1'});
    window.open('https://wa.me/573205720368', '_blank');
    fbq('track', 'Contact');
});

$('.mailTo').click(function () {
    gtag('event', 'clic_mailTo', { 'event_category': 'email', 'event_label': 'landing', 'value': '1'});
    window.open("mailto:gerencia@radiocomunicaciones.co", '_self');
});