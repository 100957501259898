$('.slider').slick({
  slidesToShow: 3,
  slidesToScroll: 1,
  dots: false,
  prevArrow: '.back',
  nextArrow: '.next',
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
      }
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 2,
        
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        dots: true,
        arrows: false
      }
    }
  ]
});